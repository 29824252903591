import React, { useEffect, useLayoutEffect, useState } from "react";
import { ReactComponent as NavLogo } from "./assets/navbar_logo.svg";
import { ReactComponent as ExLogo } from "./assets/ex_logo.svg";

import ExCheckbox from "./components/ExCheckbox/ExCheckbox";
import ExField from "./components/ExField/ExField";
import "./App.scss";
import ExRadioButton from "./components/ExRadio/ExRadio";
import ExDatePicker from "./components/ExDatePicker/ExDatePicker";
import ExButton from "./components/ExButton/ExButton";
import ExAutocomplete from "./components/ExAutocomplete/ExAutocomplete";
import Navbar from "./components/Navbar/Navbar";
import { Controller, useForm } from "react-hook-form";
import { CustomerAPI } from "./network/CustomerAPI";
import { Circuit, Customer } from "./interfaces/CustomerPlusResponse";
import moment from "moment";
import dayjs from "dayjs";

function App() {
  const [customer, setClient] = useState<Customer | undefined>(undefined);
  const [circuit, setCircuit] = useState<Circuit | undefined>(undefined);
  const [hash, setHash] = useState<string | undefined>(undefined);
  const url = window.location.href;



  const {
    control,
    watch,
    reset,
    getValues,

    formState: { isValid, isDirty },
  } = useForm({
    mode: "onChange",
  });


  const watchFields = watch();

  useEffect(() => {
    console.log(watchFields);
  }, [watchFields]);



  useEffect(() => {


    // Trova l'indice di inizio del parametro "q"
    const indexQ = url.indexOf("q=");
    
    if (indexQ !== -1) {
      // Estrai la sottostringa che inizia da "q="
      const substringAfterQ = url.substring(indexQ);
    
      // Trova l'indice della fine del valore del parametro "q"
      const indexEndOfQValue = substringAfterQ.indexOf("&");
    
      // Estrai il valore del parametro "q"
      const qValue = indexEndOfQValue !== -1 ? substringAfterQ.substring(2, indexEndOfQValue) : substringAfterQ.substring(2);
    
      setHash(qValue)
      console.log('QVALUE',qValue); 
      CustomerAPI.getDetails(qValue!).then((response) => {
        setClient(response?.data?.customer);
        setCircuit(response?.data?.circuit);
        console.log('------------->', response?.data?.customer.whatsapp_enabled)

      })
    }
 
  
  }, []);

  if (customer === undefined || circuit === undefined) return <div></div>;

  return (
    <div className="App">
      <Navbar
      circuit={circuit}
      />
      <div className="container">
        <div className="col-4"></div>
        <div className="centerColumn">
          <span className="sectionTitle">Profilo personale</span>
          <div className="form">
            <form>
              <Controller
                name="lname"
                control={control}
                defaultValue={customer!.last_name || ""}
                render={({ field, fieldState: { error } }) => (
                  <ExField
                    label="Cognome"
                    field={field}
                    clearButton={false}
                    hasStartIcon={false}
                    inputType="textfield"
                    showMaxChar={false}
                    isDisabled = {false}
                    hasError={false}
                    startingValue={customer!.last_name || ""}
                    onChange={(_) => { } }
                    isCorrect={false}
                    size={"sm"} 
                     isEnabled={false}                  />
                )}
              />
              <Controller
                name="fname"
                control={control}
                defaultValue={customer!.first_name || ""}
                render={({ field, fieldState: { error } }) => (
                  <ExField
                    label="Nome"
                    field={field}
                    clearButton={false}
                    hasStartIcon={false}

                    inputType="textfield"
                    showMaxChar={false}
                    hasError={false}
                    isCorrect={false}

                    startingValue={customer!.first_name || ""}
                    size={"sm"}
                    onChange={(event) => { } } isDisabled={false} isEnabled={false}                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                defaultValue={customer!.email || ""}
                render={({ field, fieldState: { error } }) => (
                  <ExField
                    label="Email"
                    field={field}
                    clearButton={false}
                    hasStartIcon={false}
                    showMaxChar={false}
                    hasError={false}
                    isCorrect={false}
                    size={"sm"}
                    startingValue={customer!.email || ""}
                    onChange={(event) => { } } inputType={"textfield"} isDisabled={false} isEnabled={false}                  />
                )}
              />
              <div style={{ display: "flex" }}>
                <div style={{ width: "70px" }}>
                  <Controller
                    name="mobile_icode"
                    control={control}
                    defaultValue={customer!.mobile_international_code || "+39"}
                    render={({ field, fieldState: { error } }) => (
                      <ExField
                        onChange={(event) => { } }
                        field={field}
                        clearButton={false}
                        hasStartIcon={false}
                        inputType="textfield"
                        showMaxChar={false}

                        hasError={false}
                        type="tel"
                        isCorrect={false}
                        size={"sm"}

                        startingValue={customer!.mobile_international_code || "+39"}
                        isPrefix={true} isDisabled={false}                      />
                    )}
                  />
                </div>
                <div style={{ width: "100%", marginLeft: "15px" }}>
                  <Controller
                    name="mobile"
                    control={control}
                    defaultValue={customer!.mobile || ""}
                    render={({ field, fieldState: { error } }) => (
                      <ExField
                        field={field}

                        onChange={(event) => { } }
                        label="Numero di telefono"
                        clearButton={false}
                        hasStartIcon={false}
                        inputType="textfield"
                        showMaxChar={false}
                        hasError={false}
                        isCorrect={false}
                        size={"sm"}
                        startingValue={""}
                        type={"tel"} isDisabled={false}                      />
                    )}
                  />
                </div>
              </div>
              <div
                style={{
                  marginLeft: "11px",
                  display: "flex",
                  marginBottom: "10px",
                }}
              >
                <Controller
                  name="whatsapp_enabled"
                  control={control}
                  defaultValue={customer!.whatsapp_enabled === 0 ? false : true}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <ExCheckbox
                      isChecked={value}
                      isEnabled={true}
                      
                      label="Ho whatsapp"
                      labelPlacement="end"
                      checkStyle="primary"
                      checkType="tick"
                      width={"10px"}
                      fontSize={15}
                      onClick={() => {}}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <Controller
                name="b_date"
                control={control}
                defaultValue={
                  customer!.birth_date === null || customer!.birth_date === ""
                    ? undefined
                    : customer!.birth_date
                }
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <ExDatePicker 
                    startingValue={customer!.birth_date} onChange={onChange} disablePast={false} />
                )}
              />
              <div style={{ marginTop: "20px" }}>
                <Controller
                  name="address"
                  control={control}
                  defaultValue={customer!.address || ""}
                  render={({ field, fieldState: { error } }) => (
                    <ExField
                      field={field}
                      onChange={(event) => { } }
                      label="Indirizzo"
                      clearButton={false}
                      hasStartIcon={false}
                      inputType="textfield"
                      showMaxChar={false}
                      hasError={false}
                      isCorrect={false}
                      size={"sm"}
                      startingValue={customer!.address || ""}
                      type={"tel"} isDisabled={false} isEnabled={false}                    />
                  )}
                />
              </div>
          
              <span className="sectionTitle">Comunicazioni di servizio</span>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "15px",
                  marginLeft: "12px",
                  marginRight: "12px",
                  marginTop: "10px",
                  marginBottom: "25px",
                  width: "326px",
                }}
              >
                <Controller
                  name="smsService"
                  control={control}
                  defaultValue={customer.privacy.standard.sms === "1"}

                  render={({ field: { onChange, value } }) => (
                    <ExCheckbox
                      isChecked={value}
                      isEnabled={true}
                      label="SMS"
                      labelPlacement="end"
                      checkStyle="primary"
                      checkType="tick"
                      width={"10px"}
                      fontSize={15}
                      onClick={() => {}}
                      onChange={onChange}
                    />
                  )}
                />
                <Controller
                  name="emailService"
                  control={control}
                  defaultValue={customer.privacy.standard.email === "1"}
                  render={({ field: { onChange, value } }) => (
                    <ExCheckbox
                      isChecked={value}
                      isEnabled={true}
                      label="Email"
                      labelPlacement="end"
                      checkStyle="primary"
                      fontSize={15}
                      checkType="tick"
                      width={"10px"}
                      onClick={() => {}}
                      onChange={onChange}
                    />
                  )}
                />
                <Controller
                  name="whatsappService"
                  control={control}
                  defaultValue={customer.privacy.standard.whatsapp === "1"}
                  
                  render={({ field: { onChange, value } }) => (
                    <ExCheckbox
                      isChecked={value}
                      isEnabled={true}
                      label="Whatsapp"
                      labelPlacement="end"
                      checkStyle="primary"
                      fontSize={15}
                      checkType="tick"
                      width={"10px"}
                      onClick={() => {}}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <span
                style={{
                  marginTop: "50px",
                }}
                className="sectionTitle"
              >
                Consenso marketing e promozioni
              </span>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "15px",
                  marginLeft: "12px",
                  marginRight: "12px",
                  marginTop: "10px",
                  marginBottom: "25px",
                  width: "326px",
                }}
              >
                <Controller
                  name="smsConsent"
                  control={control}
                  defaultValue={customer.privacy.marketing.sms === "1"}
                  render={({ field: { onChange, value } }) => (
                    <ExCheckbox
                      isChecked={value}
                      isEnabled={true}
                      label="SMS"
                      labelPlacement="end"
                      checkStyle="primary"
                      checkType="tick"
                      width={"10px"}
                      fontSize={15}
                      onClick={() => {}}
                      onChange={onChange}
                    />
                  )}
                />
                <Controller
                  name="emailConsent"
                  control={control}
                  defaultValue={customer.privacy.marketing.email === "1"}
                  render={({ field: { onChange, value } }) => (
                    <ExCheckbox
                      isChecked={value}
                      isEnabled={true}
                      label="Email"
                      labelPlacement="end"
                      checkStyle="primary"
                      fontSize={15}
                      checkType="tick"
                      width={"10px"}
                      onClick={() => {}}
                      onChange={onChange}
                    />
                  )}
                />
                <Controller
                  name="whatsappConsent"
                  control={control}
                  defaultValue={customer.privacy.marketing.whatsapp === "1"}
                  render={({ field: { onChange, value } }) => (
                    <ExCheckbox
                      isChecked={value}
                      isEnabled={true}
                      label="Whatsapp"
                      labelPlacement="end"
                      checkStyle="primary"
                      fontSize={15}
                      checkType="tick"
                      width={"10px"}
                      onClick={() => {}}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <span className="poweredBy">Powered by</span>
              <div className="logoWrapper">
                <ExLogo />
              </div>
            </form>
          </div>
        </div>

        <div className="col-4"></div>
      </div>
      <div className="fixed-footer">
        <div className="container">
          <div className="col-4"></div>
          <div style={{ width: "100%", maxWidth: "375px" }}>
            <ExButton
              type="primary"
              iconOnly={false}
              hasIcon={false}
              label={"Salva"}
              isEnabled={isDirty}
              leftIcon={false}
              onClick={() => {
                CustomerAPI.postDetails(
                  hash!,{
                    "lname": watchFields.lname,
                    "fname": watchFields.fname,
                    "email": watchFields.email,
                    "mobile_icode": watchFields.mobile_icode,
                    "mobile": watchFields.mobile,
                    "whatsapp_enabled": watchFields.whatsapp_enabled === true ? 1 : 0,
                    "address": watchFields.address,
                    "b_date": watchFields.b_date
                  }, {
                    "base": 1,
                    "standard": {
                        "sms":  watchFields.smsService ? 1 : 0,
                        "email": watchFields.emailService ? 1 : 0,
                        "whatsapp": watchFields.whatsappService ? 1 : 0,
                    },
                    "marketing": {
                        "sms": watchFields.smsConsent ? 1 : 0,
                        "email": watchFields.emailConsent ? 1 : 0,
                        "whatsapp": watchFields.whatsappConsent ? 1 : 0
                    },
                  }
                ).then((value) => {
                  console.log(watchFields)
                  reset(watchFields)
                })
              }}
              size="md"
            />
          </div>
          <div className="col-4"></div>
        </div>
      </div>
    </div>
  );
}

export default App;
