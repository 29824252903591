import { FunctionComponent, useEffect, useRef, useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import { IconButton, TextField } from "@mui/material";

import "./ExDatePicker.scss";
import moment from "moment";
import dayjs from "dayjs";

interface ExDatePickerProps {
  isDisabled?: boolean;
  label?: string;
  startingValue?: string;
  suffixIcon?: any;
  disablePast: boolean;
  onChange: any;
}

const ExDatePicker: FunctionComponent<ExDatePickerProps> = ({
  isDisabled = false,
  label,
  startingValue,
  suffixIcon,
  disablePast = false,
  onChange,
}: ExDatePickerProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [open, setOpen] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    if (document.activeElement === ref.current) {
      console.log(isFocused);
    } else {
      console.log(isFocused);
    }
  }, [isFocused]);

  const PickerIcons = () => {
    return (
      <div
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => setOpen(true)}
      >
        {suffixIcon != null ? suffixIcon : <></>}
     
      </div>
    );
  };

  return (
    <LocalizationProvider
    adapterLocale="it" 
    dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <div
          className={`${
            isDisabled ? "disabledTextFieldWrapper" : "textFieldWrapper"
          } 
    ${isFocused ? "focusedWrapper" : ""}
    ${isDisabled ? "disabledTextFieldWrapper" : "textFieldWrapper"}
    `}
          ref={ref}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onClick={() => setOpen(true)}
        >
          <DatePicker
            format="DD-MM-YYYY"
            disableFuture = {true}
            disablePast={disablePast}
            open={open}
            onChange={(value) => {
              console.log("DATA SELEZIONATA", value);
              onChange(dayjs(value).format("YYYY-MM-DD"));
            }}
            defaultValue={startingValue === null ? null : dayjs(startingValue)}
            onClose={() => setOpen(false)}
            slotProps={{
              textField: {
                variant: "filled",

                InputProps: {
                  disableUnderline: true,
                },
              },
            }}
            slots={{
              openPickerButton: PickerIcons,
            }}
            sx={{
              overflow: "hidden",
              width: "100%",
              borderRadius: "8px",
              border: "2px solid transparent",
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",

              ".MuiInputBase-input": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "transparent",
                marginBottom: label === undefined ? "15px" : "0px",
              },
              "& .MuiFilledInput-root:hover": {
                backgroundColor: "transparent",

                "@media (hover: none)": {
                  backgroundColor: "transparent",
                },
              },
              "& .MuiFilledInput-root.Mui-focused": {
                backgroundColor: "transparent",
              },
              ".Mui-focused": {
                backgroundColor: "transparent",
              },
              "& .MuiFilledInput-root": {
                backgroundColor: "transparent",
              },
              "&  .Mui-focused:last-child": {
                backgroundColor: "transparent",
              },

              "& .MuiOutlinedInput-root": {
                position: "relative",
              },

              ".MuiInputLabel-shrink": {
                fontFamily: "Inter",
                color: "#6E7191",
              },
              ".MuiFormLabel-root": {
                fontFamily: "Inter",
              },
              ".MuiInputLabel-root": {
                boxShadow: "none",
                color: "#6E7191",
                fontSize: "16px",
                letterSpacing: "0.25px",
                fontFamily: "Inter",
                opacity: isDisabled ? "0.6" : "1",
                backgroundColor: "transparent",
                fontWeight: 400,
              },

              " .Mui.disabled:focus": {
                backgroundColor: "transparent",
              },
              ".MuiInputBase-root:focus": {
                backgroundColor: "transparent",
              },
              ".MuiInputBase-root:hover": {
                backgroundColor: "transparent",
              },
              ".Mui-disabled": {
                backgroundColor: "transparent!important",
              },
            }}
          />
        </div>
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default ExDatePicker;
