import { FunctionComponent, useState } from "react";
import "./Navbar.scss";
import { ReactComponent as NavLogo } from "../../assets/navbar_logo.svg";
import { Divider } from "@mui/material";
import { Circuit } from "../../interfaces/CustomerPlusResponse";


interface NavbarProps {
  circuit: Circuit
}

const Navbar: FunctionComponent<
  NavbarProps
> = ({
  circuit
}: NavbarProps) => {

  return (
    <div className="fixed-header">
        <div className="container">
            <nav>
                {circuit.logo !== null ? 
                     <img src={circuit.logo}
                     width={250}
                     alt="img_logo" ></img> :
                <span className="storeName">{circuit.label}</span>}
            </nav>
        </div>
    </div>
  );
};

export default Navbar;
