import React, { FC, useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel } from "@mui/material";
import ExFormLabel from "../ExFormLabel/ExFormLabel";
import CheckIcon from "@mui/icons-material/Check";
import RemoveIcon from "@mui/icons-material/Remove";

interface ExCheckboxProps {
  isEnabled: boolean;
  labelPlacement: "start" | "end";
  checkStyle: "primary" | "secondary";
  checkType: "tick" | "indeterminate";
  label?: string;
  width?: string;
  isChecked?: boolean;
  fontSize?: number;
  icon?: React.ReactNode;
  onClick: () => void;
  onChange?: any;
}

const ExCheckbox: FC<ExCheckboxProps> = (props: ExCheckboxProps) => {
  return (
    <FormControlLabel
      disabled={!props.isEnabled}
      labelPlacement={props.labelPlacement}
      sx={{
        ".MuiTypography-root": {
          marginLeft: "5px!important",
        },
      }}
      label={
        <ExFormLabel
          label={props.label}
          labelPlacement={props.labelPlacement}
        ></ExFormLabel>
      }
      control={
        <div
          style={{
            width: props.width !== undefined ? props.width : "40px",
            height: props.width !== undefined ? props.width : "40px",
            padding: "5px",
            borderRadius: "4px",
            backgroundColor: props.isChecked
              ? props.checkStyle === "primary"
                ? "#2645E6"
                : "#293641"
              : "#ddd",
            display: "flex",
            alignItems: "center",
            opacity: props.isEnabled ? 1 : 0.6,
            justifyContent: "center",
            position: "relative",
          }}
        >
          <Checkbox
            onChange={(event, value) => {
              props.onClick();
              if (props.onChange !== undefined) {
                props.onChange(value);
              }
            }}
            icon={<CheckIcon style={{ opacity: 0 }} />}
            checkedIcon={
              props.icon !== undefined ? (
                props.icon
              ) : props.checkType !== "indeterminate" ? (
                <svg
                  width="24px"
                  height="24px"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  color="#FFFFFF"
                  style={{ padding: "4px" }}
                >
                  <path
                    d="M5 13l4 4L19 7"
                    stroke="#FFFF"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              ) : (
                <RemoveIcon />
              )
            }
            disableRipple={true}
            disabled={!props.isEnabled}
            checked={props.isChecked}
            sx={{
              color: props.checkStyle !== "primary" ? "#293641" : "#293641",
              position: "relative",
              backgroundColor: "transparent",
              padding: "0px",
              "&.Mui-disabled": {
                width: props.width !== "" ? props.width : "40px",
                height: props.width !== "" ? props.width : "40px",
                backgroundColor: props.isChecked
                  ? props.checkStyle === "primary"
                    ? "#2645E6"
                    : "#293641"
                  : "#ddd",
                opacity: 0.6,
              },
              " .MuiSvgIcon-root": {
                fontSize: props.fontSize !== undefined ? props.fontSize : 20,
                color: "white",
                position: "absolute!important",
                marginLeft: "0px",
                marginTop: "0px",
                marginRight: "0px",
                marginBottom: "0px",
              },
            }}
          ></Checkbox>
        </div>
      }
    ></FormControlLabel>
  );
};

export default ExCheckbox;
