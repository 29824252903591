// src/apis/configs/axiosConfigs.ts

import axios, { AxiosError } from "axios";

export const api = axios.create({
  withCredentials: false,
  baseURL: "https://api.exagonplus.com/v1",
  headers: {
    Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfG4g3ujbAf35HoiqMoKReaWQiO5ktf9rsSI6ImV4YWdvbjEiLCJuYW1lIjoiRXhhZ29uIEFQSSIsInN1cm5hbWUiOiJFeGFnb24ghHV0GQUcPfMjTsY1g17nQVBJIiwifjrkdc9edxAxMjIwNTk2fQ.v222zfmUNl38LbphJjIizOTzDbLu686J0_90QQyfbdo',
    "Content-Type": "application/json",
  },
});

// Defining a custom error handler for all APIs
const errorHandler = (error: AxiosError) => {
  const statusCode = error.response?.status;

  return Promise.reject(error);
};

// Registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, (error: AxiosError) => {
  return errorHandler(error);
});
