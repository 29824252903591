import React, { FC } from 'react';
import   './ExFormLabel.scss';
import Typography from '@mui/material/Typography';

interface ExFormLabelProps {
  labelPlacement: "start" | "end";
  label?: string;
}

const ExFormLabel: FC<ExFormLabelProps> = ({labelPlacement, label}: ExFormLabelProps) => (
  <Typography
  className={`${
    labelPlacement === "start"
      ? "labelPlacementRight"
      : "labelPlacementLeft"
  }`}
>{`${label ?? ""}`}</Typography>
);

export default ExFormLabel;
