import React, { FC } from "react";
import  "./ExButton.scss";
import { Circle } from "react-bootstrap-icons";

interface ExButtonProps {
  isEnabled: boolean;
  type: "primary" | "secondary";
  iconOnly: boolean;
  hasIcon: boolean;
  leftIcon: boolean;
  size: "xl" | "lg" | "md" | "sm";
  label?: string;
  onClick?: any;
}

function getStyleBySize(size: ExButtonProps["size"]) {
  return {
    xl: "buttonxl",
    lg: "buttonlg",
    md: "buttonmd",
    sm: "buttonsm",
  }[size];
}
function getIconButtonSize(size: ExButtonProps["size"]) {
  return {
    xl: "iconOnlyXl",
    lg: "iconOnlyLg",
    md: "iconOnlyMd",
    sm: "iconOnlySm",
  }[size];
}

function getLabelStyleBySize(size: ExButtonProps["size"]) {
  return {
    xl: "labelxl",
    lg: "labellg",
    md: "labelmd",
    sm: "labelsm",
  }[size];
}

const ExButton: FC<ExButtonProps> = ({
  isEnabled,
  type,
  iconOnly,
  hasIcon,
  leftIcon,
  size,
  label,
  onClick,
}: ExButtonProps) => (
  <div
    className={`
  ${
    isEnabled
      ? type === "primary"
        ? "enabledPrimary"
        : "enabledSecondary"
      : type === "primary"
      ? "disabledPrimary"
      : "disabledSecondary"
  } ${getStyleBySize(size)} ${iconOnly ? getIconButtonSize(size) : ""}`}
    onClick={isEnabled && onClick !== undefined ? () => onClick() : () => {}}
  >
    {((hasIcon && leftIcon) || iconOnly) && (
      <Circle size={size === "sm" ? 15 : 20} className={"icon"} />
    )}
    {!iconOnly && (
      <span className={`${"buttonLabel"} ${getLabelStyleBySize(size)}`}>
        {label ?? "Button"}
      </span>
    )}
    {hasIcon && !leftIcon && (
      <Circle size={size === "sm" ? 15 : 20} className={"icon"} />
    )}
  </div>
);

export default ExButton;
