import { api } from "../configs/axiosConfig";
import ApiResponse from "../interfaces/ApiResponse";
import { Circuit, Customer } from "../interfaces/CustomerPlusResponse";


export class CustomerAPI {
    static getDetails(
        customerId?: string
      ) {
        return api
          .request({
            url: `/ec_privacy/${customerId}`,
            method: "GET",
            data: {
    
            },
          })
          .then(
            (response: ApiResponse<ApiResponse<{ customer: Customer, circuit: Circuit }>>) =>
              response.data
          );
      }



      static postDetails(
        hash: string,
        customer: any,
        privacy: any,
      ) {
        return api
          .request({
            url: `/ec_set_my_privacy`,
            method: "POST",
            data: {
                data: {
                    "hash": hash,
                    "customers": [
                       customer
                    ],
                    "privacy": privacy
                }
           ,
            },
          })
          .then(
            (response: ApiResponse<ApiResponse<{  }>>) =>
              response.data
          );
      }
    
}

// https://api.exagonplus.com/v1/ec_privacy/aXpTVGpaMlpRRWxCcVFxZHpFUm5adHVrbG9mMC9QY2F6M2R1TWlGbEc1c2UyZzk1NlNmTTB6cXNKUWxFZndjTVducFRxRXV4K2dwSkNCWEJ6bkNQZEE9PQ%253D%253D
