import { FC, useState, useEffect, useRef } from "react";
import { IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import   "./ExField.scss";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import * as React from "react";

interface ExFieldProps {
  clearButton: boolean;
  label?: string;
  placeholder?: string;
  hasStartIcon: boolean;
  maxLength?: number;
  inputType: "textfield" | "multiline";
  type?: "number" | "tel" | "text";
  size: "sm" | "md";
  hasError: boolean;
  isCorrect: boolean;
  showMaxChar: boolean;
  subLabel?: string;
  isDisabled: boolean;
  minRow?: number;
  startingValue?: string;
  suffixIcon?: any;
  isPrefix?: boolean;
  onChange: (event: any) => void;
  field?: any;
  error?: any;
  isEnabled: boolean,
}

const ExField: FC<ExFieldProps> = ({
  clearButton,
  label,
  maxLength,
  hasStartIcon,
  inputType,
  type,
  size,
  hasError,
  isCorrect,
  subLabel,
  showMaxChar,
  placeholder,
  suffixIcon,
  isDisabled,
  startingValue,
  minRow,
  isPrefix,
  onChange,
  field,
  error,
  isEnabled = true
}: ExFieldProps) => {
  const [value, setValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const ref = useRef(null);

  const handleClearClick = () => {
    setValue("");
  };
  useEffect(() => {
    if (startingValue !== undefined) {
      setValue(startingValue);
    }
    if (document.activeElement === ref.current) {
      console.log(isFocused);
    } else {
      console.log(isFocused);
    }
  }, [isFocused]);

  const handleChange = (newValue) => {
    const regex = /^[0-9\b]+$/;
    var value = newValue.target.value.slice(1);
    console.log(value);
    if (type === "number" || type === "tel") {
      if (isPrefix === undefined || !isPrefix) {
        if (newValue.target.value === "" || regex.test(newValue.target.value)) {
          setValue(newValue.target.value);
          onChange(newValue.target.value);
        }
      } else {
        if (
          newValue.target.value === "" ||
          newValue.target.value === "+" ||
          regex.test(value)
        ) {
          if (!newValue.target.value.toString().startsWith("+")) {
            setValue("+" + newValue.target.value);
            onChange("+" + newValue.target.value);
          } else {
            setValue(newValue.target.value);
            onChange(newValue.target.value);
          }
        }
      }
    } else {
      setValue(newValue.target.value);
      onChange(newValue.target.value);
    }
  };

  return (
    <div
    style={
      {
      }
    }
    >
      <div
        className={`${
          isDisabled ?"disabledTextFieldWrapper" : "textFieldWrapper"
        } 
    ${isFocused ? "focusedWrapper" : ""}
    ${
      inputType !== "textfield"
        ? "textAreaWrapper"
        : size === "sm"
        ? "textfieldWrapperSm"
        : "textfieldWrapperMd"
    }
    ${hasError && !isDisabled ? "errorStyle" : ""}
    ${
      isCorrect && !isDisabled
        ? inputType === "textfield"
          ? "correctStyle"
          : "correctTextArea"
        : ""
    }
    ${isDisabled ? "disabledTextFieldWrapper" : "textFieldWrapper"} 
    
    `}
        ref={ref}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      >
        <TextField
          placeholder={placeholder}
          onChange={(newValue) => {
            console.log(newValue);
            handleChange(newValue);
          }}
          disabled={isDisabled}
          multiline={inputType === "multiline"}
          minRows={minRow ?? 2}
          maxRows={5}
          id="standard-basic"
          variant="filled"
          type={type}
          autoComplete="off"
          value={value}
          label={label ?? ""}
          inputProps={{
            maxLength: maxLength ?? 999999,
          }}
                    {...field}

          FormHelperTextProps={{
            className: "maxLengthLabel",
          }}
          InputProps={{
            disableUnderline: true,

            sx: {
              alignItems: inputType === "multiline" ? "flex-end" : "center",
            },
            endAdornment:
              suffixIcon != null ? (
                suffixIcon
              ) : isCorrect && !isDisabled ? (
                <div
                  style={{
                    marginTop: inputType === "multiline" ? "-10px" : "16px",
                    color: inputType === "multiline" ? "#27AE60" : "#00BA88",
                  }}
                >
                  <CheckIcon />
                </div>
              ) : hasError && !isDisabled ? (
                <div
                  className={`${"iconErrorStyle"}`}
                  style={{
                    marginTop: inputType === "multiline" ? "-10px" : "16px",
                  }}
                >
                  <ErrorIcon />
                </div>
              ) : (
                clearButton &&
                !isDisabled &&
                inputType === "textfield" && (
                  <IconButton
                    className={`${
                      hasError && !isDisabled ? "iconErrorStyle" : ""
                    }
          ${isCorrect && !isDisabled ? "iconCorrectStyle" : ""}`}
                    sx={{
                      visibility: value ? "visible" : "hidden",
                      padding: 0,
                    }}
                    onClick={handleClearClick}
                  >
                    <CloseIcon />
                  </IconButton>
                )
              ),
            startAdornment: hasStartIcon && inputType === "textfield" && (
              <div
                className={`${
                  hasError && !isDisabled ? "iconErrorStyle" : ""
                }
          ${isCorrect && !isDisabled ? "iconCorrectStyle" : ""}`}
                style={{ marginTop: "15px", marginRight: "15px" }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20Z"
                    stroke={
                      isCorrect && !isDisabled
                        ? "#00BA88"
                        : hasError && !isDisabled
                        ? "#ED2E7E"
                        : isDisabled
                        ? "#6E7191"
                        : "black"
                    }
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22 22L18 18"
                    stroke={
                      isCorrect && !isDisabled
                        ? "#00BA88"
                        : hasError && !isDisabled
                        ? "#ED2E7E"
                        : isDisabled
                        ? "#6E7191"
                        : "black"
                    }
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            ),
          }}
          sx={{
            overflow: "hidden",
            width: "100%",
            borderRadius: "8px",
            border: "2px solid transparent",
            backgroundColor: "transparent",
            display: "flex",
            justifyContent: "center",

            ".MuiInputBase-input": {
              padding:
                label === undefined
                  ? "0px 10px 0px 10px"
                  : isFocused || value !== ""
                  ? inputType === "textfield"
                    ? "25px 0px 5px 12px"
                    : "0px 0px 0px 0px"
                  : inputType === "textfield"
                  ? "25px 0px 5px 12px"
                  : "0px 0px 0px 0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "space-between",
              height: "100%",
              background: "transparent",
            },

            "& .MuiFilledInput-root:hover": {
              backgroundColor: "transparent",
              // Reset on touch devices, it doesn't add specificity
              "@media (hover: none)": {
                backgroundColor: "transparent",
              },
            },
            "& .MuiFilledInput-root.Mui-focused": {
              backgroundColor: "transparent",
            },
            ".Mui-focused": {
              backgroundColor: "transparent",
            },
            "& .MuiFilledInput-root": {
              backgroundColor: "transparent",
            },
            "&  .Mui-focused:last-child": {
              backgroundColor: "transparent",
            },
            button: {
              marginTop: inputType === "textfield" ? "" : "-15px",
            },
            "& .MuiOutlinedInput-root": {
              position: "relative",
            },
            "& .MuiIconButton-root": {
              color:
                isCorrect && !isDisabled
                  ? inputType === "multiline"
                    ? "#27AE60"
                    : "#00BA88"
                  : hasError && !isDisabled
                  ? "#ED2E7E"
                  : !isFocused
                  ? "#293641"
                  : "#000",
              position: inputType !== "multiline" ? "relative" : "absolute",
            },
            "& .Mui-focused .MuiIconButton-root": {
              color: !isFocused ? "#293641" : "#000",
            },

            ".MuiInputLabel-shrink": {
              fontFamily: value !== "" ? "Poppins" : "Inter",
            },
            ".MuiFormLabel-root": {
              fontFamily: value !== "" ? "Poppins" : "Inter",
            },
            ".MuiInputLabel-root": {
              boxShadow: "none",
              color:
                isCorrect && !isDisabled
                  ? inputType === "multiline"
                    ? "#27AE60"
                    : "#00BA88"
                  : hasError && !isDisabled
                  ? "#ED2E7E"
                  : "#6E7191",
              fontSize: "16px",
              letterSpacing:
                inputType === "textfield" &&
                !isFocused &&
                value === "" &&
                !hasStartIcon
                  ? "0.75px"
                  : "0.25px",
              fontFamily:
                value !== "" || (hasStartIcon && inputType === "textfield")
                  ? "Poppins"
                  : "Inter",
              opacity: isDisabled ? "0.6" : "1",
              backgroundColor: "transparent",
              fontWeight:
                value !== "" || (hasStartIcon && inputType === "textfield")
                  ? "500"
                  : 400,
            },
            "&  .Mui-focused:first-child": {
              color: label !== undefined && "#6E7191",
              backgroundColor: label !== undefined && "transparent",
              fontFamily: label !== undefined && "Poppins",
              fontWeight: label !== undefined && "500",
              fontSize: label !== undefined && "16px",
            },

            " .Mui.disabled:focus": {
              backgroundColor: "transparent",
            },
            ".MuiInputBase-root:focus": {
              backgroundColor: "transparent",
            },
            ".MuiInputBase-root:hover": {
              backgroundColor: "transparent",
            },
            ".Mui-disabled": {
              backgroundColor: "transparent!important",
            },
          }}
 
        ></TextField>
      </div>
      {((subLabel !== undefined && subLabel !== "") || maxLength !== 0) && (
        <span
          className={`${"subLabel"}
       ${hasError && !isDisabled ? "errorSubLabel" : ""}
       ${isCorrect && !isDisabled ? "correctSubLabel" : ""}
       ${showMaxChar ? "rightAlign" : "leftAlign"}`}
          style={{
            color: isCorrect
              ? inputType === "multiline"
                ? "#27AE60"
                : "#00BA88"
              : hasError
              ? "#ed2e7e"
              : "#6e7191",
          }}
        >
          {showMaxChar ? `${value.length}/${maxLength}` : subLabel}
        </span>
      )}
    </div>
  );
};

export default ExField;
